export function getPublicUrl(url: string) {
  try {
    const match = url.match(/\/storage\/v1\/object\/public\/([^/]+)\/(.+)$/);
    if (!match) return url;
    
    const [, bucket, fileName] = match;
    return `/${bucket}/${fileName}`;
  } catch {
    return url;
  }
}